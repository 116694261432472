import React from 'react';

const VisitNursingStationTextComponent = () => {
  return (
    <div className="text-content">
      <h1>訪問看護ステーション様へ</h1>
      <p>当法人では以下の点を重視して多くの訪問看護ステーション様と連携させて頂いております。</p>
      <ul>
        <li>① 24時間365日での臨時往診の対応。</li>
        <li>② みなし訪問看護での訪問看護臨時対応の補完。</li>
        <li>③ クリニックと訪問看護ステーションとのまめな連絡。訪問看護指示書の作成。</li>
      </ul>
      <p>当院の訪問看護はみなし訪問看護のみとしており、発熱時の採血や点滴、尿道カテーテルトラブルなどの医療的な対応を主業務としております。</p>
      <p>別表7該当の有無に関わらず個々の患者様に協力して関われることで、ステーション様の時間外対応を補完させて頂いております。</p>
    </div>
  );
};

export default VisitNursingStationTextComponent;

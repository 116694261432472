import React from 'react';

const CareManagerTextComponent = () => {
  return (
    <div className="text-content">
      <h1>地域のケアマネージャー様へ</h1>
      <p>当法人は地域包括ケアの役割を担う医療機関として、以下のサポートを提供しています：</p>
      <ul>
        <li>① 主治医意見書や訪問看護指示書などの作成を速やかに行います。</li>
        <li>② 医療機関への受診/入院調整を行います。</li>
        <li>③ 介護サービスの選定（例: 別表7該当の場合に入所できる施設、訪問看護ステーションの選定、自宅で土日に亡くなった場合の対応など）を医療的な観点から支援します。</li>
        <li>④ 24時間365日の臨時往診/訪問看護対応を行います。</li>
      </ul>
      <p>ケアマネージャー様の負担を軽減できるサポートを行うことが地域包括ケアだと考えています。</p>
      <p>今後とも何卒よろしくお願い申し上げます。</p>
    </div>
  );
};

export default CareManagerTextComponent;

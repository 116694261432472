import React, { useState } from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ isOpen, children, onClose }) => {
  const [closing, setClosing] = useState(false);

  // アニメーションが終わった後に閉じる処理を行う
  const handleClose = () => {
    setClosing(true);
    setTimeout(() => {
      setClosing(false);
      onClose();
    }, 500); // アニメーションの時間と合わせる
  };

  if (!isOpen && !closing) return null;

  return ReactDOM.createPortal(
    <div onClick={handleClose} style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
    }}>
      <div
        onClick={(e) => e.stopPropagation()} 
        className={closing ? 'modal-zoom-out' : 'modal-zoom-in'}
        style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '90%',
          maxHeight: '90%',
          overflowY: 'auto'
        }}
      >
        {children}
      </div>
    </div>,
    document.body
  );
};

export default Modal;

import React from 'react';

const NurseRecruitTextComponent = () => {
  return (
    <div className="nurse-recruit-text">
      <h1>看護師さん募集中です！</h1>
      <p>現在は2歳までの保育園の入居費は、0歳ですと世帯所得によっては100万円/円近くかかります。
        が、当法人でご勤務頂いている方は保育料は0円です。</p>
      <p>殆どが子育て中・子育てひと段落後・オンコール退勤夢中の看護師が15名いる当院で、
        医療度合いが高く、多くの地域の訪問看護ステーションとも連携しながら在宅医療を学べる当院でご勤務されませんか。
        訪問看護ステーションでの勤務ではないので雑多な書類業務がほぼありません。</p>
      <p>オンコール待機は最大で月8回、「北区東区中央区の一部部分」「豊平区白石区中央区の一部分」の分担制で、
        明けの月火は基本的には代休となります。</p>
      <p>ごう在宅ほいくえん(0～2歳児クラス)：保育料無料</p>
      <p>Mauve Beauty Clinicでのアートメイクなどの一部美容施術を職員優先で受けることができます！</p>
      <p>たくさんのご応募をお待ちしております。</p>
    </div>
  );
};

export default NurseRecruitTextComponent;

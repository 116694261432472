import React from 'react';
import './CareManagerComponent.css';
import careManagerImage from '../assets/care_manager.webp';
import CareManagerTextComponent from './CareManagerTextComponent';

const CareManagerComponent = ({ onClick }) => {
  return (
    <div className="care-manager-container component-spacing" style={{ backgroundImage: `url(${careManagerImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }} onClick={() => onClick(CareManagerTextComponent, careManagerImage)}>
      <h1>地域のケアマネージャー様へ</h1>
    </div>
  );
};

export default CareManagerComponent;

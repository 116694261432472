import React from 'react';
import './Footer.css'; // CSSファイルも別途作成してスタイリングを適用する

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>〒065-0024　札幌市東区北24条東18丁目3-25</p>
        <p>TEL：011-590-4345</p>
        <p>FAX：011-590-4346</p>
        <p>mail：gozaitaku@wind.ocn.ne.jp</p>
        <p>2024.9 Copyright ©gonakajima</p>
      </div>
    </footer>
  );
}

export default Footer;

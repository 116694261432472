import React from 'react';
import './CareFacilityComponent.css';
import caregiverImage from '../assets/caregiver.webp';
import CareFacilityTextComponent from './CareFacilityTextComponent';

const CareFacilityComponent = ({ onClick }) => {
  return (
    <div
      className="care-facility-container component-spacing"
      style={{ backgroundImage: `url(${caregiverImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
      onClick={() => onClick(CareFacilityTextComponent, caregiverImage)}
    >
      <h1>地域の介護施設様へ</h1>
    </div>
  );
};

export default CareFacilityComponent;

import React from 'react';
import { Transition } from '@headlessui/react';
import { Link } from 'react-scroll';
import logoImage from '../assets/go-fav.png';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <div className="relative">
      <Transition
        show={isOpen}
        enter="transition-transform duration-300"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition-transform duration-300"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <div className="h-screen bg-gray-800 text-white fixed top-0 left-0 p-4 z-20 w-64">
          <div className="mb-6 flex justify-center">
            <img src={logoImage} alt="Logo" className="w-16 h-16 object-contain" /> {/* Adjust the size of the image */}
          </div>
          <ul className="space-y-4">
            <li>
              <Link to="greeting" smooth={true} duration={500} className="block text-lg hover:bg-gray-700 p-2 rounded cursor-pointer">
                院長からの挨拶
              </Link>
            </li>
            <li>
              <Link to="overview" smooth={true} duration={500} className="block text-lg hover:bg-gray-700 p-2 rounded cursor-pointer">
                クリニック概要
              </Link>
            </li>
            <li>
              <Link to="achievements" smooth={true} duration={500} className="block text-lg hover:bg-gray-700 p-2 rounded cursor-pointer">
                診療実績
              </Link>
            </li>
            <li>
              <Link to="partner-hospitals" smooth={true} duration={500} className="block text-lg hover:bg-gray-700 p-2 rounded cursor-pointer">
                連携医療機関
              </Link>
            </li>
            <li>
              <Link to="care-manager-container" smooth={true} duration={500} className="block text-lg hover:bg-gray-700 p-2 rounded cursor-pointer">
                ケアマネージャー様へ
              </Link>
            </li>
            <li>
              <Link to="nursing-container" smooth={true} duration={500} className="block text-lg hover:bg-gray-700 p-2 rounded cursor-pointer">
                訪問看護ステーション様へ
              </Link>
            </li>
            <li>
              <Link to="msw-container" smooth={true} duration={500} className="block text-lg hover:bg-gray-700 p-2 rounded cursor-pointer">
                地域連携室のMSW様へ
              </Link>
            </li>
            <li>
              <Link to="care-facility-container" smooth={true} duration={500} className="block text-lg hover:bg-gray-700 p-2 rounded cursor-pointer">
                介護施設様へ
              </Link>
            </li>
            <li>
              <Link to="patient-family-container" smooth={true} duration={500} className="block text-lg hover:bg-gray-700 p-2 rounded cursor-pointer">
                患者様へ
              </Link>
            </li>
            <li>
              <Link to="blog-posts" smooth={true} duration={500} className="block text-lg hover:bg-gray-700 p-2 rounded cursor-pointer">
                ブログ
              </Link>
            </li>
            <li>
              <Link to="contact-form-container" smooth={true} duration={500} className="block text-lg hover:bg-gray-700 p-2 rounded cursor-pointer">
                お問い合わせ
              </Link>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  );
};

export default Sidebar;

import React from 'react';

const MSWTextComponent = () => {
  return (
    <div className="text-content">
      <h1>地域連携室のMSW様へ</h1>
      <p>いつも急性期病院様方には救急での受け入れ対応で助けて頂いております。</p>
      <p>当法人ではその反対側の立場から、迅速に在宅復帰ができるための医療機関であることを重視しております。</p>
      <ul>
        <li>地理的要素を除き、どのような病状でも在宅・施設復帰は可能である。</li>
        <li>まず退院することを決めてから考える。</li>
        <li>今までの主治医の治療方針と努力を尊重する。</li>
      </ul>
      <p>上記を大切なものとして、在宅復帰の受け皿としての機能を果たして参ります。</p>
    </div>
  );
};

export default MSWTextComponent;

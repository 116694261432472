import React from 'react';
import './PatientFamilyComponent.css';
import familyImage from '../assets/family_care.webp';
import PatientFamilyTextComponent from './PatientFamilyTextComponent';

const PatientFamilyComponent = ({ onClick }) => {
  return (
    <div
      className="patient-family-container component-spacing"
      style={{ backgroundImage: `url(${familyImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
      onClick={() => onClick(PatientFamilyTextComponent, familyImage)}
    >
      <h1>患者様へ</h1>
    </div>
  );
};

export default PatientFamilyComponent;

import React from 'react';
import visitNurseImage from '../assets/visit_nurse_smiling.webp';
import VisitNursingStationTextComponent from './VisitNursingStationTextComponent';

const VisitNursingStationComponent = ({ onClick }) => {
  return (
    <div
      className="nursing-container component-spacing"
      style={{ backgroundImage: `url(${visitNurseImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
      onClick={() => onClick(VisitNursingStationTextComponent, visitNurseImage)}
    >
      <h1>訪問看護ステーション様へ</h1>
    </div>
  );
};

export default VisitNursingStationComponent;

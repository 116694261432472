import React, { useState } from 'react';
import axios from 'axios';
import './ContactForm.css';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        'https://2mc19wjid9.execute-api.ap-northeast-1.amazonaws.com/prod/api/contact',
         { name, phone, message }
        );
      console.log('Server response:', response.data);
      alert('お問い合わせを受け付けました。');
      setName('');
      setPhone('');
      setMessage('');
    } catch (error) {
      console.error('Error submitting form:', error);
      if (error.response) {
        // サーバーからのレスポンスがある場合
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      } else if (error.request) {
        // リクエストは送信されたがレスポンスがない場合
        console.error('No response received:', error.request);
      } else {
        // リクエストの設定中にエラーが発生した場合
        console.error('Error setting up request:', error.message);
      }
      alert('お問い合わせを送信しました。');
    }
  };

  return (
    <div className="contact-form-container">
      <form onSubmit={handleSubmit} className="notepad-form">
        <div className="form-header">
          <h2>お問い合わせ</h2>
        </div>
        <div className="form-group">
          <label htmlFor="name">お名前</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">電話番号</label>
          <input
            type="tel"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">メッセージ</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </div>
        <button type="submit" className="submit-button" disabled={isSubmitting}>
          {isSubmitting ? '送信中...' : '送信'}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
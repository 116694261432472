import React, { useState } from 'react';
import './App.css';
import Modal from './components/Modal';
import Sidebar from './components/Sidebar';
import Greeting from './components/Greeting';
import Overview from './components/Overview';
import Achievements from './components/Achievements';
import PartnerHospitals from './components/PartnerHospitals';
import Links from './components/Links';
import CareManagerComponent from './components/CareManagerComponent';
import CareManagerTextComponent from './components/CareManagerTextComponent';
import careManagerImage from './assets/care_manager.webp';
import VisitNursingStationComponent from './components/VisitNursingStationComponent';
import VisitNursingStationTextComponent from './components/VisitNursingStationTextComponent';
import visitNurseImage from './assets/visit_nurse_smiling.webp';
import MSWComponent from './components/MSWComponent';
import MSWTextComponent from './components/MSWTextComponent';
import mswImage from './assets/msw.webp';
import CareFacilityComponent from './components/CareFacilityComponent';
import CareFacilityTextComponent from './components/CareFacilityTextComponent';
import caregiverImage from './assets/caregiver.webp';
import PatientFamilyComponent from './components/PatientFamilyComponent';
import PatientFamilyTextComponent from './components/PatientFamilyTextComponent';
import patientImage from './assets/family_care.webp';
// import BlogManager from './components/BlogManager';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import NurseRecruitmentComponent from './components/NurseRecruitmentComponent';
import NurseRecruitTextComponent from './components/NurseRecruitmentTextComponent';
import nurseImage from './assets/nurse_smiling.webp';

const App = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const showModal = (TextComponent, image) => {
    const modalContent = (
      <div style={{
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        padding: '20px',
        zIndex: 9999
      }}>
        <TextComponent />
      </div>
    );
    setModalContent(modalContent);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative flex">
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />

      {/* サイドバーを開閉する画像ボタン */}
      <button
        className="fixed top-4 left-4 z-20 p-2 rounded focus:outline-none"
        style={{
          backgroundImage: 'url(/sidebar_button.png)',
          backgroundSize: 'cover',
          width: '50px',
          height: '50px'
        }}
        onClick={toggleSidebar}
      />

      {/* メインコンテンツ */}
      <div className={`transition-all duration-300 ${isOpen ? 'ml-64' : 'ml-0'} w-full`}>
        <div className="bg-gray-100 h-screen flex items-center justify-center relative">
          {/* 動画 */}
          <div className="w-full h-full absolute top-0 left-0">
            <video src="/doctor_index.mp4" className="w-full h-full object-cover" autoPlay loop muted playsInline />
          </div>
          {/* フェードインテキスト */}
          <div className="absolute inset-0 flex items-center justify-center">
            <h1 className="text-4xl font-bold text-white opacity-0 animate-fadeInUp">
              医集会の経営理念<br />
              少子高齢化社会に感謝の気持ちを溢れかえす。<br />
              「ありがとう」を伝え合える医療を続ける。<br />
            </h1>
          </div>
        </div>
        <div className="container mx-auto max-w-screen-lg">
          <Greeting />
          <Overview />
          <Achievements />
          <PartnerHospitals />
          <Links />
          <NurseRecruitmentComponent onClick={() => showModal(NurseRecruitTextComponent, nurseImage)}/>
          <CareManagerComponent onClick={() => showModal(CareManagerTextComponent, careManagerImage)} />
          <VisitNursingStationComponent onClick={() => showModal(VisitNursingStationTextComponent, visitNurseImage)} />
          <MSWComponent onClick={() => showModal(MSWTextComponent, mswImage)} />
          <CareFacilityComponent onClick={() => showModal(CareFacilityTextComponent, caregiverImage)} />
          <PatientFamilyComponent onClick={() => showModal(PatientFamilyTextComponent, patientImage)} />
          {/* <BlogManager /> */}
          <ContactForm />
          <Footer />
        </div>
        {modalContent && <Modal isOpen={true} onClose={() => setModalContent(null)}>{modalContent}</Modal>}
      </div>

    </div>
  );
};

export default App;
import React from 'react';

const CareFacilityTextComponent = () => {
  return (
    <div className="text-content">
      <h1>地域の介護施設様へ</h1>
      <p>当法人では投薬などを行うだけでなく、入居者様に寄り添った医療内容を大切にしています。</p>
      <ul>
        <li>夜間休日の往診/訪問看護での臨時対応。</li>
        <li>ターミナルケアを希望される入居者様への対応。</li>
        <li>施設での療養継続が難しい場合の家族との相談と入院調整など。</li>
      </ul>
      <p>住宅型有料老人ホーム様の場合、当院の訪問看護は医療機関からのみなし訪問看護なので併設する訪問看護ステーションの介護請求に支障が出ない設計になっております。</p>
    </div>
  );
};

export default CareFacilityTextComponent;

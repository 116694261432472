import React from 'react';

const PartnerHospitals = () => {
  return (
    <section id="partner-hospitals" className="p-8 bg-white shadow-lg rounded-lg my-10">
      <h2 className="text-2xl font-bold mb-4">連携医療機関</h2>
      <p>
      <a href="https://www.huhp.hokudai.ac.jp/">北海道大学病院</a><br />
      <a href="https://web.sapmed.ac.jp/hospital/">札幌医科大学病院</a><br />
      <a href="https://www.city.sapporo.jp/hospital/">市立札幌病院</a><br />
      <a href="https://www.dou-kouseiren.com/byouin/sapporo/">JA北海道厚生連札幌厚生病院 </a><br />
      <a href="https://hospital.jrhokkaido.co.jp/">JR札幌病院</a><br />
      <a href="https://www.tonan.kkr.or.jp/">国家公務員共済組合連合会斗南病院</a><br />
      <a href="&quot;https:/smc.kkr.or.jp/">KKR札幌医療センター</a><br />
      <a href="https://hokkaido.jcho.go.jp/">独立行政法人地域医療機能推進機構JCHO北海道病院</a><br />
      <a href="http://www.nishioka-hosp.jp/">社会医療法人恵和会西岡病院</a><br />
      <a href="https://www.sapporojikeikai.or.jp/facility/jikeikai/">社会福祉法人さっぽろ慈啓会 慈啓会病院</a><br />
      <a href="https://www.asuka-gp.or.jp/sapporo/">医療法人明日佳 札幌明日佳病院</a><br />
      <a href="https://keijinkai.com/nishimaruyama/">医療法人渓仁会 札幌西円山病院</a><br />
      <a href="https://www.ims.gr.jp/ims-sapporo/">医療法人明生会 イムス札幌消化器中央総合病院</a><br />
      <a href="https://sap-kojk.jp/">社会医療法人孝仁会 札幌孝仁会記念病院</a><br />
      <a href="https://www.teishinkai.jp/thp/">医療法人禎心会 札幌禎心会病院</a><br />
      <a href="https://www.higashi-tokushukai.or.jp/">医療法人徳洲会 札幌東徳洲会病院</a><br />
      <a href="https://www.kin-ikyo-chuo.jp/">北海道勤労者医療協会 勤医協中央病院</a><br />
      <a href="https://www.tenshi.or.jp/">医療法人社団母恋 天使病院</a><br />
      <a href="https://www.hsh.or.jp/">医療法人東札幌病院</a><br />
      <a href="https://www.hokuyu-aoth.org/">医療法人北楡会 札幌北楡病院</a><br />
      <a href="https://www.keiyukaisapporo.or.jp/">社会医療法人 恵佑会札幌病院</a><br />
      <a href="https://aiiku-hp.or.jp/m/">医療法人菊郷会 愛育病院</a><br />
      </p>
      </section>
  );
};

export default PartnerHospitals;

import React from 'react';

const Achievements = () => {
  return (
    <section id="achievements" className="section-container">
      <h2 className="text-2xl font-bold mb-4">実績</h2>
      <p className="section-text">
        前年度実績(令和5年12月～令和6年3月)<br />
        訪問診療：480件<br />
        初診往診+退院時会議：71件<br />
        在宅/施設看取り：20件<br />
      </p>
    </section>
  );
};

export default Achievements;

import React from 'react';
import './MSWComponent.css';
import mswImage from '../assets/msw.webp';
import MSWTextComponent from './MSWTextComponent';

const MSWComponent = ({ onClick }) => {
  return (
    <div
      className="msw-container component-spacing"
      style={{ backgroundImage: `url(${mswImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
      onClick={() => onClick(MSWTextComponent, mswImage)}
    >
      <h1>地域連携室のMSW様へ</h1>
    </div>
  );
};

export default MSWComponent;

import React from 'react';

const Overview = () => {
    return (
      <section id="overview" className="section-container">
        <h2 className="text-2xl font-bold mb-4">クリニック概要</h2>
        <p className="section-text">
        科目：内科　緩和ケア内科<br />
        機能強化型在宅療養支援診療所(連携型)<br />
        在宅緩和ケア充実診療所加算<br />
        ＜対応可能処置＞<br />
        胃瘻・腸瘻・経鼻経管栄養<br />
        在宅酸素・在宅人工呼吸・CPAPなどの呼吸管理<br />
        末梢点滴・中心静脈栄養などの点滴処置<br />
        尿道カテーテル・膀胱瘻・腎瘻などの泌尿器科的処置<br />
        オピオイド投薬・鎮静処置などの緩和ケアに関する処置<br />
        PCA・カフティポンプ・OPATなどの輸液処置<br />
        褥瘡・創傷・ストマなどの皮膚科的な処置<br />
        膝関節注射・肩関節注射・ブロック注射<br />
        在宅での看取り・緩和ケア・ターミナルケアの対応
        </p>
      </section>
    );
  };
  
  export default Overview;
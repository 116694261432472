import React from 'react';

const PatientFamilyTextComponent = () => {
  return (
    <div className="text-content">
      <h1>患者様へ</h1>
      <p>ごう在宅クリニックは札幌市内一円に、在宅医療を提供しているクリニックです。</p>
      <p>病院に通院することが難しい老衰や癌の終末期などの患者様方に往診をして、ご自宅での生活を支えております。</p>
      <p>かかりつけ医として何かあった時に夜間、休日に往診や看護師が訪問でき、</p>
      <p>必要な時には地域の病院に受診や入院の相談を行う対応を行っています。</p>
      <p>また、夜間休日にお子さん方などの発熱に往診する対応も令和６年４月より行っています。</p>
      <p>急な発熱や感染症など一度限りの往診申し込みは<a href="https://go-doctor.clinic">『Go Doctor』</a>からお願い致します。</p>
      <p>これからも札幌市の在宅医療に貢献できるよう、医師や看護師などスタッフ一同尽力して参ります。</p>
    </div>
  );
};

export default PatientFamilyTextComponent;

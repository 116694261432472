/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "logAPI",
            "endpoint": "https://zd4pzg2kef.execute-api.ap-northeast-1.amazonaws.com/prod",
            "region": "ap-northeast-1"
        },
        {
            "name": "contactAPI",
            "endpoint": "https://2mc19wjid9.execute-api.ap-northeast-1.amazonaws.com/prod",
            "region": "ap-northeast-1"
        },
        {
            "name": "postAPI",
            "endpoint": "https://1hyn6fxx6c.execute-api.ap-northeast-1.amazonaws.com/prod",
            "region": "ap-northeast-1"
        }
    ]
};


export default awsmobile;

import React from 'react';

const Greeting = () => {
  return (
    <section id="greeting" className="section-container">
      <h2 className="text-2xl font-bold mb-4">院長からのご挨拶</h2>
      <p className="section-text">
      当法人は平成19年に「ごう在宅クリニック」として開院し、札幌市は中央区、豊平区、白石区、東区、及び北区を主たる診療地域として活動して参りました。<br />
      この17年間の間に在宅医療を取り巻く環境は様変わりし、また地域の患者様からの自宅での看取り医療を受けたいとのご要望が大きくなっていることを感じております。<br />
      法人として札幌市内でも各地域において、24時間365日の臨時対応をより強化し、患者様方が安心して在宅での生活を行えるよう、東区に分院を開設しております。<br />

      今後も「ごう在宅クリニック」「ごう在宅ひがしクリニック」とが協力して、札幌市内での在宅医療を提供差し上げられるように尽力して参ります。<br />
      また当法人は訪問看護ステーションは併設せずにクリニックからの訪問看護(みなし訪問看護)を行うことで、地域のステーション様方と臨機応変な協力ができる体制を敷いております。<br />
      別表7該当の有無にかかわらず、地域の訪問看護ステーション様方の力をお借りしながら地域に根差した医療機関になれるよう、努力して参ります。<br />

      今後とも「ごう在宅クリニック」「ごう在宅ひがしクリニック」共に、なお一層のご指導ご鞭撻のほどを承りますよう、よろしくお願い申し上げます。<br />
      医療法人社団医集会　理事長　中嶋豪<br />
      </p>
    </section>
  );
};

export default Greeting;
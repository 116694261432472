import React from 'react';
import './NurseRecruitmentComponent.css';
import NurseRecruitmentTextComponent from './NurseRecruitmentTextComponent';
import nurseImage from '../assets/nurse_smiling.webp';

const NurseRecruitmentComponent = ({ onClick }) => {
    return (
        <div
        className="nurse-recruitment-container component-spacing"
        style={{ backgroundImage: `url(${nurseImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
        onClick={() => onClick(NurseRecruitmentTextComponent, nurseImage)}
        >
        <h1>看護師さん募集中</h1>
        </div>
    );
};
  
export default NurseRecruitmentComponent;

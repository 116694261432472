import React from 'react';
import styles from './Links.module.css';

const Links = () => {
  return (
    <section className={styles.container}>
      <h2 className={styles.header}>リンク</h2>
      <ul className={styles.list}>
        <li className={styles.listItem}>
          <a href="http://go-zaitaku-clinic.com/">
            <img src="/logo_honnin.jpg" alt="医集会本院の在宅クリニック"/>
            医集会本院の在宅クリニック。<br />
            合計14名医師の体制で24時間かかりつけ医対応を行っております。
          </a>
        </li>
        <li className={styles.listItem}>
          <a href="https://mauve-beauty-clinic.com/">
            <img src="/logo_mauve.jpg" alt="美容クリニック"/>
            医集会が運営する美容クリニック。<br />
            カタチを変えない自然な美しさをご提案差し上げます。<br />
            医集会で勤務頂ける方は施術特典がございます。
          </a>
        </li>
        <li className={styles.listItem}>
          <a href="http://go-athome-hoiku.com/">
            <img src="/logo_hoiku.jpg" alt="保育園"/>
            お医者さんが作った企業主導型保育園。<br />
            医集会で勤務頂ける方は保育料無料です。
          </a>
        </li>
        <li className={styles.listItem}>
          <a href="https://appletree-clinic.com/">
            <img src="/logo_ringo.jpg" alt="在宅クリニック"/>
            医集会が運営する豊平区の在宅クリニック。<br />
            本院と連携して24時間かかりつけを行っております。
          </a>
        </li>
        <li className={styles.listItem}>
          <a href="https://go-doctor.clinic/godoctor/">
            <img src="/logo_godoctor.png" alt="godoctorクリニック"/>
            札幌市のcalldoctorはgodoctorになりました！<br />
            コールドクターをお探し、発熱など一回限りの往診はこちらです。
          </a>
        </li>
      </ul>
    </section>
  );
};

export default Links;
